import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src311988156/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { IconAdd, IconEdit, IconAnchor, IconArrowDown, IconArrowUp, IconArrowLeft, IconArrowRight, IconBack, IconBusiness, IconCaretDown, IconCaretDownCircle, IconClose, IconGuide, IconHome, IconChart, IconMenu, IconNotify, IconReport, IconSetting, IconSetting2, IconStar, IconStartSelected, IconThumb, IconUser, IconLogout } from '@madup-inc/mad-design';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "icon"
    }}>{`Icon`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={IconAdd} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<IconAdd />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IconAdd,
      IconEdit,
      IconAnchor,
      IconArrowDown,
      IconArrowUp,
      IconArrowLeft,
      IconArrowRight,
      IconBack,
      IconBusiness,
      IconCaretDown,
      IconCaretDownCircle,
      IconClose,
      IconGuide,
      IconHome,
      IconChart,
      IconMenu,
      IconNotify,
      IconReport,
      IconSetting,
      IconSetting2,
      IconStar,
      IconStartSelected,
      IconThumb,
      IconUser,
      IconLogout,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconAdd mdxType="IconAdd" />
    </Playground>
    <br />
    <h2 {...{
      "id": "different-types"
    }}>{`Different types`}</h2>
    <Playground __position={3} __code={'<div style={{ display: \'flex\', justifyContent: \'space-between\' }}>\n  <IconAdd />\n  <IconEdit />\n  <IconAnchor />\n  <IconArrowLeft />\n  <IconArrowUp />\n  <IconArrowRight />\n  <IconArrowDown />\n  <IconBack />\n  <IconBusiness />\n  <IconCaretDown />\n  <IconCaretDownCircle />\n  <IconClose />\n  <IconGuide />\n  <IconHome />\n  <IconChart />\n  <IconMenu />\n  <IconNotify />\n  <IconReport />\n  <IconSetting />\n  <IconSetting2 />\n  <IconStar />\n  <IconStartSelected />\n  <IconThumb />\n  <IconUser />\n  <IconLogout />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IconAdd,
      IconEdit,
      IconAnchor,
      IconArrowDown,
      IconArrowUp,
      IconArrowLeft,
      IconArrowRight,
      IconBack,
      IconBusiness,
      IconCaretDown,
      IconCaretDownCircle,
      IconClose,
      IconGuide,
      IconHome,
      IconChart,
      IconMenu,
      IconNotify,
      IconReport,
      IconSetting,
      IconSetting2,
      IconStar,
      IconStartSelected,
      IconThumb,
      IconUser,
      IconLogout,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
    <IconAdd mdxType="IconAdd" />
    <IconEdit mdxType="IconEdit" />
    <IconAnchor mdxType="IconAnchor" />
    <IconArrowLeft mdxType="IconArrowLeft" />
    <IconArrowUp mdxType="IconArrowUp" />
    <IconArrowRight mdxType="IconArrowRight" />
    <IconArrowDown mdxType="IconArrowDown" />
    <IconBack mdxType="IconBack" />
    <IconBusiness mdxType="IconBusiness" />
    <IconCaretDown mdxType="IconCaretDown" />
    <IconCaretDownCircle mdxType="IconCaretDownCircle" />
    <IconClose mdxType="IconClose" />
    <IconGuide mdxType="IconGuide" />
    <IconHome mdxType="IconHome" />
    <IconChart mdxType="IconChart" />
    <IconMenu mdxType="IconMenu" />
    <IconNotify mdxType="IconNotify" />
    <IconReport mdxType="IconReport" />
    <IconSetting mdxType="IconSetting" />
    <IconSetting2 mdxType="IconSetting2" />
    <IconStar mdxType="IconStar" />
    <IconStartSelected mdxType="IconStartSelected" />
    <IconThumb mdxType="IconThumb" />
    <IconUser mdxType="IconUser" />
    <IconLogout mdxType="IconLogout" />
  </div>
    </Playground>
    <blockquote>
      <p parentName="blockquote">{`Renamed) 레버에서는 이전 이름을 사용 중(21.6.10 Keating)`}</p>
      <ol parentName="blockquote">
        <li parentName="ol"><inlineCode parentName="li">{`ads`}</inlineCode>{` to `}<inlineCode parentName="li">{`edit`}</inlineCode></li>
        <li parentName="ol"><inlineCode parentName="li">{`manage`}</inlineCode>{` to `}<inlineCode parentName="li">{`chart`}</inlineCode></li>
      </ol>
    </blockquote>
    <br />
    <h2 {...{
      "id": "different-sizes"
    }}>{`Different sizes`}</h2>
    <Playground __position={5} __code={'<IconEdit size={20} />\n<IconEdit size={30} />\n<IconEdit size={40} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IconAdd,
      IconEdit,
      IconAnchor,
      IconArrowDown,
      IconArrowUp,
      IconArrowLeft,
      IconArrowRight,
      IconBack,
      IconBusiness,
      IconCaretDown,
      IconCaretDownCircle,
      IconClose,
      IconGuide,
      IconHome,
      IconChart,
      IconMenu,
      IconNotify,
      IconReport,
      IconSetting,
      IconSetting2,
      IconStar,
      IconStartSelected,
      IconThumb,
      IconUser,
      IconLogout,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconEdit size={20} mdxType="IconEdit" />
  <IconEdit size={30} mdxType="IconEdit" />
  <IconEdit size={40} mdxType="IconEdit" />
    </Playground>
    <h2 {...{
      "id": "different-colors"
    }}>{`Different colors`}</h2>
    <Playground __position={6} __code={'<IconEdit style={{ color: \'blue\' }} />\n<IconEdit style={{ color: \'red\' }} />\n<IconEdit style={{ color: \'green\' }} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IconAdd,
      IconEdit,
      IconAnchor,
      IconArrowDown,
      IconArrowUp,
      IconArrowLeft,
      IconArrowRight,
      IconBack,
      IconBusiness,
      IconCaretDown,
      IconCaretDownCircle,
      IconClose,
      IconGuide,
      IconHome,
      IconChart,
      IconMenu,
      IconNotify,
      IconReport,
      IconSetting,
      IconSetting2,
      IconStar,
      IconStartSelected,
      IconThumb,
      IconUser,
      IconLogout,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconEdit style={{
        color: 'blue'
      }} mdxType="IconEdit" />
  <IconEdit style={{
        color: 'red'
      }} mdxType="IconEdit" />
  <IconEdit style={{
        color: 'green'
      }} mdxType="IconEdit" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      